import React from 'react';

export const PageCover = React.forwardRef((props, ref) => {
    return (
        <div className="page page-cover" ref={ref} data-density="hard" style={{ display: "none" }}>
            <div className="page-content page-image" style={{ backgroundImage: `url(${props.src})`, backgroundSize: 'cover' }} >
                <h2>{props.children}</h2>
            </div>
        </div>
    );
});
