import React from 'react';
import './Page.scss';

export const Page = React.forwardRef((props, ref) => {
    return (
        <div className="page" ref={ref}>
            <div className={`page-content page-image-${props.side}`} style={{ backgroundImage: `url(${props.src})`, backgroundSize: 'cover'  }}>
                {props.header && <div className="non-semantic-protector">
                    <div className="ribbon">
                        <h3 className="ribbon-content header">{props.header}</h3>
                        <h4 className="ribbon-content subHeader">{props.subHeader}</h4>
                    </div>
                </div>}
                <div className="page-text">{props.children}</div>
                {props.footer && <div className="page-footer">{props.footer}</div>}
            </div>
        </div>
    );
});
