import React from 'react';
import HTMLFlipBook from 'react-pageflip';
import { images } from './Utils';
import { Page } from './Page';
import { PageCover } from './PageCover';
import useWindowDimensions from './useWindowDimensions';

export function ArrowOfTimeBook(props) {

    const { height, width } = useWindowDimensions();

    const myHeight = height * 840 > width * 570 ? width * 570 / 840 : height;
    const myWidth = width * 570 > height * 840 ? height * 420 / 570 : width / 2;

    return (
        <HTMLFlipBook
            width={myWidth}
            height={myHeight}
            maxWidth="420"
            maxHeight="570"
            autoSize={true}
            size="stretch"
            drawShadow={true}
            maxShadowOpacity={0.5}
            showCover={true}
            className="demo-book html-book">
            <PageCover src={images['./Capa.jpg']}>⤖ Arrow of Time ⤖</PageCover>
            <Page header={"December, 2000"} subHeader={"Ana (22), Manuel (23)"} src={images['./2000-12.jpg']} side="left" />
            <Page src={images['./2000-12.jpg']} side="right" />
            <Page header={"December, 2001"} subHeader={"Ana (23), Manuel (24)"} src={images['./2001-12.jpg']} side="left" />
            <Page src={images['./2001-12.jpg']} side="right" />
            <Page header={"December, 2002"} subHeader={"Ana (24), Manuel (25)"} src={images['./2002-12.jpg']} side="left" />
            <Page src={images['./2002-12.jpg']} side="right" />
            <Page header={"December, 2003"} subHeader={"Ana (25), Manuel (26)"} src={images['./2003-12.jpg']} side="left" />
            <Page src={images['./2003-12.jpg']} side="right" />
            <Page header={"December, 2004"} subHeader={"Ana (26), Manuel (27)"} src={images['./2004-12.jpg']} side="left" />
            <Page src={images['./2004-12.jpg']} side="right" />
            <Page header={"December, 2005"} subHeader={"Ana (27), Manuel (28)"} src={images['./2005-12.jpg']} side="left" />
            <Page src={images['./2005-12.jpg']} side="right" />
            <Page header={"December, 2006"} subHeader={"Ana (28), Manuel (29)"} src={images['./2006-12.jpg']} side="left" />
            <Page src={images['./2006-12.jpg']} side="right" />
            <Page header={"December, 2007"} subHeader={"Ana (29), Manuel (3m), Manuel (30)"} src={images['./2007-12.jpg']} side="left" />
            <Page src={images['./2007-12.jpg']} side="right" />
            <Page header={"December, 2008"} subHeader={"Ana (30), Manuel (15m), Manuel (31)"} src={images['./2008-12.jpg']} side="left" />
            <Page src={images['./2008-12.jpg']} side="right" />
            <Page header={"December, 2009"} subHeader={"Ana (31), Manuel (2), Manuel (32)"} src={images['./2009-12.jpg']} side="left" />
            <Page src={images['./2009-12.jpg']} side="right" />
            <Page header={"December, 2010"} subHeader={"Mariana (4m), Ana (32), Manuel (3), Manuel (33)"} src={images['./2010-12.jpg']} side="left" />
            <Page src={images['./2010-12.jpg']} side="right" />
            <Page header={"December, 2011"} subHeader={"Mariana (16m), Ana (33), Manuel (4), Manuel (34)"} src={images['./2011-12.jpg']} side="left" />
            <Page src={images['./2011-12.jpg']} side="right" />
            <Page header={"December, 2012"} subHeader={"Mariana (2), Ana (34), Manuel (5), Manuel (35)"} src={images['./2012-12.jpg']} side="left" />
            <Page src={images['./2012-12.jpg']} side="right" />
            <Page header={"December, 2013"} subHeader={"Mariana (3), Ana (35), Manuel (6), Manuel (36)"} src={images['./2013-12.jpg']} side="left" />
            <Page src={images['./2013-12.jpg']} side="right" />
            <Page header={"December, 2014"} subHeader={"Ana+1 (36), Mariana (4), Manuel (7), Manuel (37)"} src={images['./2014-12.jpg']} side="left" />
            <Page src={images['./2014-12.jpg']} side="right" />
            <Page header={"December, 2015"} subHeader={"Teresa (11m), Ana (37), Mariana (5), Manuel (8), Manuel (38)"} src={images['./2015-12.jpg']} side="left" />
            <Page src={images['./2015-12.jpg']} side="right" />
            <Page header={"December, 2016"} subHeader={"Ana (38), Teresa (23m), Mariana (6), Manuel (9), Manuel (39)"} src={images['./2016-12.jpg']} side="left" />
            <Page src={images['./2016-12.jpg']} side="right" />
            <Page header={"December, 2017"} subHeader={"Ana (39), Teresa (2), Mariana (7), Manuel (10), Manuel (40)"} src={images['./2017-12.jpg']} side="left" />
            <Page src={images['./2017-12.jpg']} side="right" />
            <Page header={"December, 2018"} subHeader={"Ana (40), Teresa (3), Mariana (8), Manuel (11), Manuel (41)"} src={images['./2018-12.jpg']} side="left" />
            <Page src={images['./2018-12.jpg']} side="right" />
            <Page header={"December, 2019"} subHeader={"Ana (41), Teresa (4), Mariana (9), Manuel (12), Manuel (42)"} src={images['./2019-12.jpg']} side="left" />
            <Page src={images['./2019-12.jpg']} side="right" />
            <Page header={"December, 2020"} subHeader={"Ana (42), Teresa (5), Mariana (10), Manuel (13), Manuel (43)"} src={images['./2020-12.jpg']} side="left" />
            <Page src={images['./2020-12.jpg']} side="right" />
            <Page header={"December, 2021"} subHeader={"Ana (43), Teresa (6), Mariana (11), Manuel (14), Manuel (44)"} src={images['./2021-12.jpg']} side="left" />
            <Page src={images['./2021-12.jpg']} side="right" />
            <Page header={"December, 2022"} subHeader={"Ana (44), Mariana (12), Teresa (7), Manuel (15), Manuel (45)"} src={images['./2022-12.jpg']} side="left" />
            <Page src={images['./2022-12.jpg']} side="right" />
            <Page header={"December, 2023"} subHeader={"Ana (45), Mariana (13), Teresa (8), Manuel (16), Manuel (46)"} src={images['./2023-12.jpg']} side="left" />
            <Page src={images['./2023-12.jpg']} side="right" />
            <Page header='About...' subHeader='... and credits' src={images['./Credits.jpg']} side="left">
                <h4>About</h4>
                <p>It all started in the month of December 2000, when two friends decided to go for a walk across
                    the <a href="https://en.wikipedia.org/wiki/Sintra_Mountains" target="_blank" rel="noreferrer">Sintra Mountains</a>. Soon, these friends had turned to
                    partners, and they decided to remember that day one year earlier with a similar picture. Over time, partners turned to lovers, eventually engaged, got
                    married, built a family, had 3 beautiful children, while always keeping the tradition.
                </p>
                <p>These pictures are taken from <a href="https://www.megalithic.co.uk/article.php?sid=36073" target="_blank" rel="noreferrer">Tholos do Monge</a>,
                    a megalithic monument dating back to the Chalcolithic. Also worth noticing is the development of the vegetation in the background, from year to year.
                </p>
                <h4>Credits</h4>
                <p>
                    This website is a very simple <a href="https://reactjs.org/docs/create-a-new-react-app.html" target="_blank" rel="noreferrer">React</a> app with a few
                    packages worth acknowledging:
                </p>
                <ul>
                    <li><a href="https://www.npmjs.com/package/react-pageflip" target="_blank" rel="noreferrer">react-pageflip</a>, which was used to create the realistic
                        and beautiful page turning effect.</li>
                    <li>the unavoidable <a href="https://www.npmjs.com/package/react-pageflip" target="_blank" rel="noreferrer">bootstrap</a>, the world’s most popular
                        front-end open source toolkit.</li>
                </ul>
            </Page>
            <Page src={images['./Credits.jpg']} side="right">
                <h4>More information</h4>
                <p>The term <strong>Arrow of Time</strong> was definitely not coined by us. A couple of years into this tradition, our attention was drawn to an amazing
                    initiative - <a href="http://www.diegogoldberg.com/arrow" target="_blank" rel="noreferrer">Diego Goldberg's Arrow of Time</a> - which inspired us, and
                    from which we respectfully borrowed the brand.
                </p>
                <p>As a bonus, here's the Covid-19 pandemic version:</p>
                <p><img width="100%" src={images['./Covid.jpg']} alt="The family, wearing masks." />
                </p>
                <p>You can reach us on Instagram:
                </p>
                <ul>
                    <li><a href="https://www.instagram.com/anacandeiasoliveira/" target="_blank" rel="noreferrer">Ana's Instagram</a></li>
                    <li><a href="https://www.instagram.com/mroliv77/" target="_blank" rel="noreferrer">Manuel's Instagram</a></li>
                </ul>
            </Page>
            <PageCover src={images['./Contracapa.jpg']}>⤖ See you next year ⤖</PageCover>
        </HTMLFlipBook>
    );
}
